.page-contacts {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        @media (max-width: 576px) {
            width: calc(100% - 0px);
        }

        .contacts-container {
            position: relative;
            margin-bottom: 64px;

            .contacts-container-bg {
                width: calc(100vw - 15px);
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 100%;
                transform: translate(calc(1120px / 2 - 50%), 0);
                background: var(--color-gray-shader);

                &.full-width {
                    width: 100vw;
                }

                @media (max-width: 1120px) {
                    transform: translate(0, 0);
                }

                @media (max-width: 576px) {
                    width: 100vw;
                }
            }

            .contacts-container-inner {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 64px;
                margin-bottom: 96px;
                gap: 32px;
                position: relative;

                @media (max-width: 1120px) {
                    width: calc(100% - 8px - 8px);
                    padding-left: 8px;
                    padding-right: 8px;
                }

                @media (max-width: 810px) {
                    flex-direction: column;
                    margin-top: 32px;
                    margin-bottom: 48px;
                }

                .contacts-card {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    border-radius: 32px;
                    background-color: white;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 32px;
                    justify-content: space-between;
                    box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);
                    gap: 60px;

                    @media (max-width: 1050px) {
                        gap: 32px;
                    }

                    @media (max-width: 810px) {
                        padding: 32px 16px;
                        align-items: center;
                        flex-direction: column-reverse;
                        gap: 24px;
                    }

                    .contacts-card-container {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        gap: 32px;
                        width: 100%;

                        @media (max-width: 810px) {
                            gap: 8px;
                        }

                        .contacts-link {
                            color: var(--color-green-main);
                            font-weight: 700;
                        }

                        .icon {
                            height: 72px;
                        }

                        .title-text-h2 {
                            text-align: left;

                            @media (max-width: 810px) {
                                font-size: 20px;
                            }
                        }

                        .label {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            margin: 0;
                            color: var(--color-font);
                            font-family: var(--font-primary);
                        }

                        .phone {
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 32px;
                        }
                    }
                }

                .left-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                    flex: 1;
                }

                .right-container {
                    display: flex;
                    flex-direction: row;
                    border-radius: 32px;
                    background-color: white;
                    box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);
                    width: calc(100% - 32px - 32px);
                    padding: 32px;
                    align-self: stretch;
                    flex: 1;
                    max-width: 480px;

                    @media (max-width: 810px) {
                        align-items: center;
                        padding: 32px 16px;
                        width: calc(100% - 16px - 16px);
                        max-width: 100%;
                    }

                    .contacts-form {
                        width: 100%;
                        display: flex;
                        gap: 32px;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        margin-bottom: auto;
                        margin-top: 7px;

                        @media (max-width: 810px) {
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }

                        .form-message-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            width: 100%;

                            .form-message-counter {
                                font-style: italic;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 400;
                                text-align: right;
                                padding: 0 16px;
                                margin-top: 8px;
                            }
                        }

                        .form-terms-label {
                            font-style: normal;
                            font-size: 15px;
                            line-height: 16px;
                            font-weight: 400;
                            margin-bottom: -4px;
                            margin-top: -16px;
                            text-decoration: none;
                            cursor: pointer;
                            color: var(--color-text);

                            b {
                                color: var(--color-highlight);
                            }
                        }
                    }
                }
            }
        }
    }
}
