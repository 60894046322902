.layout--default {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;

    &.gray {
        background: var(--color-gray-shader);
    }

    .page {
        width: var(--page-width);
        align-items: center;
        position: relative;

        @media (max-width: 1120px) {
            width: 100%;
            padding: 0;
        }
    }
}
