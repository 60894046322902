.page-home {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        .label {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--color-font);
            font-family: var(--font-primary);
        }

        .description {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--color-font);
            font-family: var(--font-primary);
            padding: 0;
            margin: 0;

            @media (max-width: 1120px) {
                width: calc(100% - 16px - 16px);
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .top-container {
            width: 100%;
            padding-top: 64px;
            padding-bottom: 64px;
            display: flex;
            gap: 32px;

            @media (max-width: 1120px) {
                width: calc(100% - 8px - 8px);
                padding-left: 8px;
                padding-right: 8px;
            }

            @media (max-width: 810px) {
                gap: 16px;
            }

            @media (max-width: 576px) {
                margin-top: 32px;
                margin-bottom: 0;
                width: 100%;
                padding: 0;
                gap: 0;
            }
        }

        .middle-container {
            position: relative;

            .middle-container-bg {
                width: calc(100vw - 15px);
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 100%;
                transform: translate(calc(1120px / 2 - 50%), 0);
                background: var(--color-gray-shader);

                &.full-width {
                    width: 100vw;
                }

                @media (max-width: 1120px) {
                    transform: translate(0, 0);
                }

                @media (max-width: 576px) {
                    width: 100vw;
                }
            }

            .middle-container-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 64px;
                padding-bottom: 64px;
                position: relative;

                @media (max-width: 1120px) {
                    width: calc(100% - 8px - 8px);
                    padding-left: 8px;
                    padding-right: 8px;
                }

                .title-text-h1 {
                    @media (max-width: 576px) {
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 32px;

                        max-width: 170px;
                        white-space: pre-wrap;
                    }
                }

                .about-container {
                    width: calc(100%);
                    display: flex;
                    gap: 32px;
                    margin-top: 32px;
                    margin-bottom: 32px;

                    @media (max-width: 810px) {
                        gap: 16px;
                    }

                    @media (max-width: 576px) {
                        flex-direction: column;
                        gap: 24px;
                    }

                    .item {
                        display: flex;
                        flex-direction: column;
                        flex: 1 1 0;
                        border-radius: 24px;
                        background-color: white;
                        align-items: center;
                        justify-content: center;
                        padding: 33px 24px 32px 24px;
                        box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);

                        .icon {
                            margin-bottom: 24px;
                            height: 87px;

                            @media (max-width: 576px) {
                                height: 58px;
                            }
                        }

                        .label {
                            text-align: center;

                            @media (max-width: 576px) {
                                max-width: 240px;
                            }
                        }
                    }
                }

                .button {
                    margin-top: 32px;
                }

                .middle-container-button-link {
                    color: var(--color-primary);
                    text-decoration: none;
                }
            }
        }

        .bottom-container {
            width: calc(100% - 60px - 60px);
            max-height: 844px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 32px;
            background-color: white;
            padding: 60px;
            margin-top: 64px;
            margin-bottom: 64px;
            position: relative;
            background: var(--color-gray-shader);

            .title-text-h1 {
                @media (max-width: 576px) {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                }
            }

            @media (max-width: 1120px) {
                width: calc(100% - 60px - 60px - 8px - 8px);
                margin-left: 8px;
                margin-right: 8px;
            }

            @media (max-width: 576px) {
                width: calc(100% - 16px - 16px - 8px - 8px);
                margin-left: 8px;
                margin-right: 8px;
                padding-right: 16px;
                padding-left: 16px;
            }

            .map-container {
                max-width: 995px;
                width: 100%;
                margin-top: 32px;
                margin-bottom: 20px;
                max-height: 600px;
                overflow: hidden;

                @media (max-width: 576px) {
                    margin-bottom: 28px;
                }

                .map {
                    width: 100%;
                    height: 100%;
                }
            }

            .type-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 32px;
                width: 100%;

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 26px;
                }

                .type-inner-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    .label {
                        margin: 0;
                    }

                    .left-box {
                        width: 24px;
                        height: 24px;
                        background-color: var(--color-dark-green);
                    }

                    .right-box {
                        width: 24px;
                        height: 24px;
                        background-color: var(--color-green-main);
                    }
                }
            }
        }
    }
}
