.tab-view {
    margin: 0;
    padding: 0;

    header {
        display: flex;
        flex-direction: row;

        .tab {
            cursor: pointer;
            position: relative;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: var(--color-text);
            font-family: var(--font-primary);
            padding: 16px 32px;
            min-height: calc(100% - 16px - 16px);
            margin: 0;

            @media (max-width: 960px) {
                min-width: 0px;
                padding: 16px 16px;
            }

            @media (max-width: 860px) {
                font-size: 15px;
                padding: 16px 14px;
            }

            @media (max-width: 800px) {
                font-size: 14px;
                padding: 16px 12px;
            }

            @media (max-width: 740px) {
                font-size: 13px;
                padding: 16px 12px;
                width: 130px;
            }

            @media (max-width: 660px) {
                font-size: 13px;
                padding: 16px 12px;
                width: 100px;
            }

            &.tab--active {
                border-radius: 24px 24px 0px 0px;
                background: var(--color-gray-shader);
            }

            .before {
                position: absolute;
                top: 0px;
                left: -24px;
                bottom: 0px;
                width: 24px;
                background: var(--color-gray-shader);

                .inner {
                    width: 100%;
                    height: 100%;
                    border-radius: 0px 0px 24px 0px;
                    background: white;
                }
            }

            .after {
                position: absolute;
                top: 0px;
                right: -24px;
                bottom: 0px;
                width: 24px;
                background: var(--color-gray-shader);

                .inner {
                    width: 100%;
                    height: 100%;
                    border-radius: 0px 0px 0px 24px;
                    background: white;
                }
            }
        }
    }

    .tab-view-pages {
        border-radius: 0px 32px 32px 32px;
        background: var(--color-gray-shader);
        margin: 0;
        padding: 0;

        &.rounded {
            border-radius: 32px 32px 32px 32px;
        }
    }
}
