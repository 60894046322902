.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: rgba(21, 22, 23, 0.8);
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    overflow-y: auto;

    .modal {
        display: flex;
        height: fit-content;
        flex-direction: column;
        border-radius: 32px;
        margin-top: 64px;
        margin-bottom: 64px;
        background-color: var(--color-primary);
        width: calc(800px - 32px - 32px);
        padding: 32px;
        justify-content: center;

        @media (max-width: 882px) {
            margin-left: 16px;
            margin-right: 16px;
            width: calc(100% - 32px);
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .header-container {
            display: flex;
            flex-direction: row;
            position: relative;

            .header-picture {
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 24px;
                width: 254px;
                height: 254px;
                background-position: 50%;

                @media (max-width: 866px) {
                    width: 204px;
                    height: 204px;
                }
            }

            .header-title-container {
                margin-left: 34px;
                height: 177px;
                width: calc(100% - 254px - 32px);

                .title {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 40px;

                    @media (max-width: 866px) {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32px;
                    }
                }

                .description {
                    margin-top: 16px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    padding-bottom: 62px;
                    border-bottom: 1px solid var(--color-placeholder);

                    @media (max-width: 866px) {
                        font-size: 14px;
                        line-height: 22px;
                        padding-bottom: 42px;
                    }
                }
            }

            .modal-close-button {
                position: absolute;
                top: 0;
                right: 0;
                border: none;
                display: flex;
                width: 48px;
                height: 48px;
                padding: 12px;
                justify-content: center;
                align-items: center;
                border-radius: 24px;
                background: var(--color-primary);
                box-shadow: 0px 4px 8px 0px rgba(73, 92, 136, 0.15);
                cursor: pointer;
            }
        }

        .custom-modal-body {
            margin-top: 42px;
            margin-left: 2px;
            margin-right: 5px;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            @media (max-width: 866px) {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}
