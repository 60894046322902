.page-founds {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -96px;
    overflow-y: hidden;

    @media (max-width: 576px) {
        margin-top: -32px;
    }

    .content-card {
        border-radius: 32px;
        background: var(--color-primary);
        width: 100%;
        height: fit-content;
        margin-bottom: 64px;
        height: calc(100% - 64px);

        .card-container {
            width: calc(100% - 96px - 96px);
            margin-top: 64px;
            margin-bottom: 64px;
            margin-left: 96px;
            margin-right: 96px;
            display: flex;
            gap: 32px;

            @media (max-width: 1120px) {
                width: calc(100% - 64px - 64px);
                margin-left: 64px;
                margin-right: 64px;
            }

            @media (max-width: 992px) {
                width: calc(100% - 32px - 32px);
                margin-left: 32px;
                margin-right: 32px;
                gap: 16px;
            }

            @media (max-width: 576px) {
                width: calc(100%);
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 48px;
                margin-top: 32px;
                gap: 0;
                display: block;
            }
        }

        .shaded-content {
            margin-left: 96px;
            margin-right: 96px;
            height: fit-content;
            border-radius: 32px;
            background: var(--color-gray-shader);
            padding-bottom: 56px;
            margin-bottom: 64px;

            &.white {
                @media (max-width: 576px) {
                    background: white;
                    margin-bottom: 24px;
                }
            }

            @media (max-width: 1120px) {
                width: calc(100% - 64px - 64px);
                margin-left: 64px;
                margin-right: 64px;
            }

            @media (max-width: 992px) {
                width: calc(100% - 8px - 8px);
                margin-left: 8px;
                margin-right: 8px;
                padding-bottom: 32px;
            }

            @media (max-width: 576px) {
                margin-bottom: 48px;
            }

            .shaded-content-title {
                padding-top: 63px;
                color: var(--color-font);
                text-align: center;
                font-family: var(--font-primary);
                font-size: 32px;
                font-style: normal;
                font-weight: 900;
                line-height: 40px;
                margin: 0;
                margin-bottom: 32px;

                @media (max-width: 754px) {
                    font-size: 22px;
                }

                @media (max-width: 576px) {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    padding-top: 48px;
                    margin-bottom: 12px;
                }
            }

            .shaded-content-laying-cards-mobile {
                @media (max-width: 576px) {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 12px;

                    width: calc(100% + 8px + 8px);
                    padding-left: -8px;
                    padding-right: -8px;
                    transform: translate(-8px, 0%);
                }
            }

            .shader-container {
                display: flex;
                flex-direction: column;
                margin-left: 64px;
                margin-right: 64px;
                flex-shrink: 0;
                width: calc(100% - 64px - 64px);

                @media (max-width: 992px) {
                    width: calc(100% - 32px - 32px);
                    margin-left: 32px;
                    margin-right: 32px;
                }

                .content-container {
                    width: 100%;
                    margin-bottom: 32px;

                    @media (max-width: 576px) {
                        margin-top: 32px;
                        height: 503px;
                    }

                    .padding-top {
                        margin-top: 25px;
                    }
                }

                .text {
                    font-family: var(--font-primary);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    color: var(--color-font);
                    word-wrap: break-word;
                    @media (max-width: 754px) {
                        font-size: 14px;
                    }

                    @media (max-width: 576px) {
                        font-size: 16px;
                        width: calc(100% - 16px - 16px);
                    }
                }

                @media (max-width: 576px) {
                    width: calc(100% - 16px - 16px);
                    margin: 0;
                    padding-left: 16px;
                    padding-right: 16px;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    border-radius: 24px;
                    background: var(--color-gray-shader);
                    margin-top: -460px;
                }

                &.second {
                    @media (max-width: 576px) {
                        margin-top: 48px;
                    }

                    .content-container {
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                        @media (max-width: 576px) {
                            margin-top: 32px;
                            margin-bottom: 32px;
                            flex-direction: column;
                        }

                        &.left-side-picture {
                            flex-direction: row-reverse;
                            @media (max-width: 576px) {
                                flex-direction: column;
                            }
                        }

                        .right-text-container {
                            margin-left: 32px;
                        }

                        .left-picture-container {
                            position: relative;
                            border-left: 1px solid var(--color-green-main);
                            height: fit-content;
                            width: 256px;
                            text-align: center;

                            @media (max-width: 576px) {
                                align-items: center;
                                width: 148.621px;
                                margin-left: auto;
                                margin-right: auto;
                                margin-top: 32px;
                            }

                            .left-side-pic-title {
                                &.first {
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-image: url('../../../public/image/otp_allapotcsalad_tagjai_negy_ev_logo.svg');
                                    height: 81px;
                                    width: 152px;
                                }

                                &.second {
                                    width: 224px;
                                    height: 81px;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-image: url('../../../public/image/otp_allapotcsalad_tagjai_ot_ev_logo.svg');
                                }

                                span {
                                    transform: translate(0%, 75%);
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    height: 30.833px;
                                    color: #fff;
                                    font-family: var(--font-primary);
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 32px;
                                }

                                @media (max-width: 754px) {
                                    font-size: 16px;
                                    &.first {
                                        height: 52px;
                                        width: 100px;
                                    }
                                    &.second {
                                        height: 52px;
                                        width: 144px;
                                    }
                                    span {
                                        transform: translate(0%, 30%);
                                        font-size: 16px;
                                    }
                                }
                                @media (max-width: 576px) {
                                    &.second {
                                        height: 52px;
                                        width: 148px;
                                    }
                                }
                            }
                        }

                        &.right-side-picture {
                            display: flex;
                            flex-direction: row;
                            margin-bottom: 0;

                            @media (max-width: 576px) {
                                flex-direction: column;
                            }
                            .left-text-container {
                                width: 60%;
                                @media (max-width: 850px) {
                                    width: 50%;
                                }
                                @media (max-width: 754px) {
                                    width: 60%;
                                }
                                @media (max-width: 576px) {
                                    width: 100%;
                                }
                            }
                        }

                        .right-picture-container {
                            margin-left: 32px;
                            width: 40%;
                            position: relative;

                            @media (max-width: 754px) {
                                margin-left: 5px;
                            }
                            @media (max-width: 576px) {
                                margin-left: 0px;
                                width: 52%;
                                margin-left: 24%;
                                margin-right: 24%;
                                background-image: url('../../../public/image/otp_alanlott_befektetesi_idotav_mobile_all.svg');
                                background-repeat: no-repeat;
                                height: 100.818px;
                                flex-shrink: 0;
                                margin-top: 32px;
                            }

                            .fisrt-picture-container {
                                display: inline-flex;
                                height: 100%;
                                margin-right: 5.48px;

                                .pic-title {
                                    transform: rotate(-90deg);
                                    font-family: var(--font-primary);
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;
                                    color: var(--color-primary);
                                    text-align: center;
                                    position: absolute;
                                    left: 10px;
                                    right: 5px;
                                    bottom: 29px;

                                    @media (max-width: 754px) {
                                        font-size: 16px;
                                    }

                                    @media (max-width: 576px) {
                                        visibility: hidden;
                                    }

                                    &.left-padding {
                                        left: 13px;
                                        right: 0px;
                                    }
                                }

                                .picture-div {
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    width: 66.324px;
                                    flex-shrink: 0;
                                    z-index: 2;
                                    position: absolute;
                                    bottom: 0px;
                                    border-bottom: 1px solid var(--color-green-main);
                                    padding: 2px;

                                    &.first {
                                        background-image: url('../../../public/image/otp_alanjott_befektetesi_idotav_hozam_1_logo.svg');
                                        height: 156px;
                                        left: 0;
                                    }

                                    &.second {
                                        background-image: url('../../../public/image/otp_alanjott_befektetesi_idotav_hozam_2_logo.svg');
                                        height: 186px;
                                        left: 92px;
                                    }

                                    &.third {
                                        background-image: url('../../../public/image/otp_alanjott_befektetesi_idotav_hozam_3_logo.svg');
                                        height: 207px;
                                        left: 188px;
                                    }

                                    @media (max-width: 754px) {
                                        width: 49px;

                                        &.first {
                                            height: 117px;
                                        }

                                        &.second {
                                            height: 139.5px;
                                            left: 68px;
                                        }

                                        &.third {
                                            height: 155.25px;
                                            left: 141px;
                                        }
                                    }

                                    @media (max-width: 576px) {
                                        visibility: hidden;
                                    }
                                }

                                .picture-other-div {
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    width: 66.324px;
                                    flex-shrink: 0;
                                    position: absolute;
                                    bottom: 0;
                                    border-bottom: 1px solid var(--color-green-main);

                                    &.first {
                                        background-image: url('../../../public/image/otp_alanjott_befektetesi_idotav_kockazat_1_logo.svg');
                                        height: 158px;
                                        left: 26px;
                                    }

                                    &.second {
                                        background-image: url('../../../public/image/otp_alanjott_befektetesi_idotav_kockazat_2_logo.svg');
                                        height: 188px;
                                        left: 124px;
                                    }

                                    &.third {
                                        background-image: url('../../../public/image/otp_alanjott_befektetesi_idotav_kockazat_3_logo.svg');
                                        height: 209px;
                                        left: 220px;
                                    }

                                    @media (max-width: 754px) {
                                        width: 49px;

                                        &.first {
                                            height: 119px;
                                            left: 19.5px;
                                        }

                                        &.second {
                                            height: 141.5px;
                                            left: 93px;
                                        }

                                        &.third {
                                            height: 157.25px;
                                            left: 165px;
                                        }
                                    }

                                    @media (max-width: 576px) {
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                    }
                }

                .shader-container-title {
                    padding-top: 63px;
                    color: var(--color-font);
                    text-align: center;
                    font-family: var(--font-primary);
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 40px;
                    margin: 0;
                    margin-bottom: 32px;

                    @media (max-width: 754px) {
                        font-size: 22px;
                    }

                    @media (max-width: 576px) {
                        padding-top: 0;
                        font-size: 20px;
                        font-weight: 700;
                        font-style: normal;
                        line-height: 32px;
                        margin-bottom: 8px;
                        text-align: left;
                    }

                    &.subtitle {
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 32px;
                        text-align: left;
                        padding-top: 0;
                        margin-bottom: 16px;
                        @media (max-width: 754px) {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }

    .afterword {
        display: flex;
        max-width: 657px;
        justify-content: center;
        margin-bottom: 64px;
        margin-left: 24px;
        margin-right: 24px;

        h3 {
            color: var(--color-font);
            text-align: center;
            font-family: var(--font-primary);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;

            @media (max-width: 754px) {
                font-size: 18px;
            }
        }
    }
}
