.page-terms {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -96px;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        @media (max-width: 576px) {
            width: calc(100% - 0px);
        }

        .content-card {
            border-radius: 32px;
            background: var(--color-primary);
            width: 100%;
            margin-bottom: 64px;

            .text-container {
                width: calc(100% - 62px - 66px);
                height: 100%;
                display: flex;
                padding-left: 62px;
                padding-top: 64px;
                margin-bottom: 64px;

                @media (max-width: 576px) {
                    width: calc(100% - 24px - 24px);
                    padding-left: 24px;
                    padding-top: 32px;
                    margin-bottom: 48px;
                }

                .content-simple-text {
                    line-height: 24px;
                    font-weight: 400;

                    b {
                        color: var(--color-highlight);
                    }
                }

                .content-title {
                    font-size: 20px;
                    text-align: left;
                    margin-top: 16px;
                    margin-bottom: 8px;
                }

                .form-terms-label {
                    line-height: 24px;
                    font-weight: 400;
                    text-decoration: none;
                    cursor: pointer;
                    color: var(--color-text);

                    b {
                        color: var(--color-highlight);
                    }
                }
            }
        }
    }
}
