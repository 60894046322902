.tab-docs {
    align-items: self-start;
    width: calc(100% - 64px - 64px);
    display: flex;
    flex-direction: column;
    padding: 64px 64px 44px 64px;

    @media (max-width: 576px) {
        width: calc(100% - 16px - 16px);
        padding: 16px 16px 0px 16px;
    }

    .icon {
        width: 16px;
        height: 15px;
        margin-right: 8px;
    }

    a {
        text-decoration: none;
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        color: var(--color-green-main);
        font-family: var(--font-primary);
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 24px;
    }
}
