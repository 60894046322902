@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

// @font-face declarations moved to -> src/asset/scss/font.scss
@import './font.scss';

:root {
    --font-sans-pro: 'OTP Source Sans Pro', sans-serif;
    --font-squad: 'OTP Squad', 'OTP Source Sans Pro', sans-serif;
    --font-primary: var(--font-sans-pro);
    --color-dark: #151617;
    --color-gray: #7d828b;
    --color-light-grey: #ababab;
    --color-gray-shader: #eceff6;
    --color-white-grey: #e3e8f1;
    --color-background-gray: #f6f7fa;
    --color-conservative-green: #98c219;
    --color-orange: #f9b430;
    --color-yellow: #ffcc00;
    --color-red: #bd3031;
    --color-light-green: #a8ccab;
    --color-dark-green: #006648;
    --color-green-main: #52ae30;
    --color-green-hover: #74be59;
    --color-green-pressed: #428b26;
    --color-light-gray: #f0f0f0;
    --color-white: #ffffff;
    --color-blue: #487ec9;
    --color-blue-dark: #0074c6;
    --color-primary: var(--color-white);
    --color-font: var(--color-dark);
    --color-placeholder: var(--color-gray);
    --color-highlight: var(--color-green-hover);
    --color-border: #d6d6d6;
    --color-inactive: #f7f7f7;
    --transition-speed: 333ms;
    --page-width: 1120px;
}

html {
    font-size: 16px;
    // scroll-behavior: smooth;

    scroll-behavior: auto !important;
}

body {
    color: var(--color-font);
    font-family: var(--font-primary);
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1;
    margin: 0;
    overflow-y: scroll;
}

// ::-webkit-scrollbar {
//     width: 8px;
//     height: 8px;
// }

// ::-webkit-scrollbar-track {
//     background: transparent;
// }

// ::-webkit-scrollbar-thumb {
//     background: var(--color-light-grey);
//     border-radius: 4px;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: var(--color-dark);
// }

.button {
    background-color: var(--color-green-main);
    border: 0px solid transparent;
    border-radius: 24px;
    height: 48px;
    color: var(--color-white);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 8px 32px;
    transition: background-color var(--transition-speed);
    cursor: pointer;

    &:hover {
        background-color: var(--color-highlight);
    }

    &:active {
        background-color: var(--color-green-pressed);
    }

    &[disabled] {
        // background-color: var(--color-gray);
        opacity: 0.25;
        pointer-events: none;
        cursor: not-allowed;
    }

    &--ghost {
        background-color: white;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        transition: border-color var(--transition-speed), color var(--transition-speed);

        &:hover {
            background-color: var(--color-white);
            border-color: var(--color-highlight);
            color: var(--color-highlight);
        }

        &[disabled] {
            background-color: var(--color-white);
            border-color: var(--color-gray);
            color: var(--color-gray);
            opacity: 0.25;
        }
    }
}

.title-text-h1 {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    margin: 0;
    color: var(--color-font);
    font-family: var(--font-primary);
}

.title-text-h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    color: var(--color-font);
    font-family: var(--font-primary);
}

.title-text-h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    color: var(--color-font);
    font-family: var(--font-primary);
}

.label-text {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: var(--color-primary);
    font-family: var(--font-primary);
}

.header-text {
    text-align: left;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    margin: 0;
    color: var(--color-primary);
    font-family: var(--font-squad);
}
