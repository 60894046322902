.tab-founds {
    align-items: self-start;
    width: calc(100% - 64px - 64px);
    display: flex;
    flex-direction: column;
    padding: 58px 64px 48px 64px;

    @media (max-width: 576px) {
        width: calc(100% - 16px - 16px);
        padding: 0px 16px 16px 16px;
    }

    .title-text-h2 {
        text-align: left;
        margin: 0;
    }
    .without-top-margin {
        margin-top: 16px;
    }

    .desc-text {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-font);
        font-family: var(--font-primary);
        margin-top: 10px;
        margin-bottom: 12px;
    }

    .first-container {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-top: 16px;
        margin-bottom: 16px;

        @media (max-width: 576px) {
            flex-direction: column-reverse;
        }

        .first-image {
            width: 156px;
            height: 156px;
        }

        .desc-text {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .second-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-bottom: 16px;
        margin-top: 16px;

        @media (max-width: 576px) {
            flex-direction: column;
        }

        .second-left-container {
            flex: 20;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .title-text-h2 {
                margin-top: 0px;
                margin-bottom: 0px;
            }

            .desc-text {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }

        .second-right-container {
            flex: 18;
            display: flex;
            flex-direction: row;
            gap: 28px;

            @media (max-width: 1060px) {
                gap: 18px;
            }

            @media (max-width: 576px) {
                gap: 18px;
            }

            .second-image {
                width: 156px;
                height: 156px;

                @media (max-width: 576px) {
                    width: 122px;
                    height: 156px;
                }
            }

            .type-container {
                display: flex;
                flex-direction: column;
                align-items: self-start;
                justify-content: center;
                width: 100%;
                gap: 40px;
                max-height: 156px;
                max-width: 430px;

                @media (max-width: 1060px) {
                    gap: 20px;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 20px;
                }

                @media (max-width: 370px) {
                    flex-direction: column;
                    gap: 10px;
                }

                .type-inner-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    .label {
                        text-align: left;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: var(--color-font);
                        font-family: var(--font-primary);
                        margin: 0;
                    }

                    .top-box {
                        width: 17px !important;
                        height: 17px;
                        min-width: 17px;
                        background-color: #f4a600;
                    }

                    .center-box {
                        width: 17px;
                        height: 17px;
                        min-width: 17px;
                        background-color: #f60;
                    }

                    .bottom-box {
                        width: 17px;
                        height: 17px;
                        min-width: 17px;
                        background-color: #b00000;
                    }
                }
            }
        }
    }
}
