.found-card {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    border-radius: 16px;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    padding: 32px 16px 32px 16px;
    box-shadow: 0px 16px 18px 0px rgba(73, 92, 136, 0.15);

    .title-text-h3 {
        font-weight: 600;
    }

    .button {
        width: fit-content;
        margin-top: 24px;
    }
}
