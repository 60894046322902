.card {
    position: relative;
    flex: 1;

    .picture {
        width: 100%;
        max-height: 168px;
        align-self: stretch;
        border-radius: 32px 32px 0px 0px;
    }

    .card-content {
        display: flex;
        width: calc(100% - 32px - 32px);
        flex-direction: column;
        align-items: center;
        justify-content: top;
        border-radius: 0px 0px 32px 32px;
        margin-top: -3px;
        padding: 32px;

        @media (max-width: 810px) {
            padding: 16px;
            width: calc(100% - 16px - 16px);
        }

        &.open {
            height: 548px;
        }

        &.bg {
            height: fit-content;
        }

        &.close {
            height: 168px;
        }

        &.first {
            background: var(--color-conservative-green);
        }

        &.second {
            background: var(--color-orange);
        }

        &.third {
            background: var(--color-red);
        }

        .card-title {
            color: var(--color-primary);
            text-align: center;
            font-family: var(--font-primary);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            margin: 0px;
            padding: 0px;
            white-space: pre-wrap;
        }

        .card-description {
            color: var(--color-primary);
            text-align: center;
            font-family: var(--font-primary);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin: 0px;
            padding: 0px;
            padding-top: 16px;

            &.hidden {
                display: none;
            }
        }

        .bottom-content {
            margin-top: auto;

            .button {
                @media (max-width: 880px) {
                    padding: 8px 16px;
                }

                @media (max-width: 680px) {
                    padding: 8px 8px;
                }

                &.card-content-footer-button {
                    margin-top: 24px;
                    min-width: 128px;

                    .footer-button-link {
                        color: var(--color-primary);
                        text-decoration: none;
                    }
                }

                &.bg {
                    height: fit-content;
                }
            }
        }

        @media (max-width: 1045px) {
            .card-description {
                font-size: 13px;
            }
        }

        @media (max-width: 992px) {
            &.open {
                height: fit-content;
                min-height: 168px;
            }

            .card-description {
                display: none;
            }
        }

        @media (max-width: 810px) {
            .card-title {
                font-size: 18px;
            }
        }

        @media (max-width: 650px) {
            .card-title {
                font-size: 16px;
            }
        }
    }
}
