.page-founds-conservative {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        @media (max-width: 576px) {
            width: calc(100% - 0px);
        }

        .top-text {
            margin-top: 64px;
            margin-bottom: 64px;
            text-align: left;
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            color: var(--color-text);
            font-family: var(--font-primary);

            @media (max-width: 1120px) {
                width: calc(100% - 24px - 24px);
                padding-left: 24px;
                padding-right: 24px;
            }

            @media (max-width: 576px) {
                width: calc(100% - 24px - 24px);
                padding-left: 24px;
                padding-right: 24px;
                margin-top: 32px;
                margin-bottom: 64px;
            }
        }

        .tab-div {
            margin-bottom: 64px;

            @media (max-width: 576px) {
                width: calc(100% - 8px - 8px);
                padding-left: 8px;
                padding-right: 8px;
                margin-bottom: 48px;
            }

            .tab-items-container {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .tab-item-container {
                    display: flex;
                    flex-direction: column;
                    border-radius: 32px 32px 32px 32px;
                    background-color: var(--color-gray-shader);
                    margin: 0;
                    padding: 0;

                    .tab-item {
                        width: 100%;
                        height: fit-content;
                        background: #e3e8f1;

                        border-radius: 32px;
                        display: flex;
                        flex-direction: row;
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            background: #e3e8f1;
                        }

                        .tab-item-title {
                            width: calc(100% - 56px - 1px);
                            height: 100%;
                            padding: 16px;
                            margin: 0;
                            text-align: left;
                            color: var(--color-font);
                            font-family: var(--font-primary);
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                        }

                        .arrow-button {
                            width: 56px;
                            height: 56px;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            background-color: transparent;
                            border-color: transparent;
                            border: 1px solid transparent;
                            border-top-right-radius: 32px;
                            border-bottom-right-radius: 32px;

                            &:hover {
                                background: #e3e8f1;
                            }

                            .arrow-icon {
                                -moz-transition: all 0.2s linear;
                                -webkit-transition: all 0.2s linear;
                                transition: all 0.2s linear;
                            }

                            .rotate {
                                -moz-transform: rotate(180deg);
                                -webkit-transform: rotate(180deg);
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        .bottom-div {
            position: relative;

            .bottom-div-bg {
                width: calc(100vw - 15px);
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: calc(100% + 32px);
                transform: translate(calc(1120px / 2 - 50%), 0);
                background: var(--color-gray-shader);

                &.full-width {
                    width: 100vw;
                }

                @media (max-width: 1120px) {
                    transform: translate(0, 0);
                }

                @media (max-width: 576px) {
                    width: 100vw;
                }
            }

            .bottom-text {
                position: relative;
                padding-top: 64px;
                padding-bottom: 94px;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: var(--color-text);
                font-family: var(--font-primary);

                @media (max-width: 1120px) {
                    width: calc(100% - 24px - 24px);
                    padding-left: 24px;
                    padding-right: 24px;
                }

                @media (max-width: 576px) {
                    padding-top: 48px;
                    padding-bottom: 48px;
                    white-space: pre-wrap;
                }
            }
        }
    }
}
