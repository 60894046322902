.exchange-modal {
    width: calc(100% - 24px - 24px);
    height: calc(100% - 16px - 16px);
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;

    &.hidden {
        display: none;
    }

    .exchange-modal-top-bar {
        width: 100%;
        display: flex;
        flex-direction: row;

        .close-button {
            display: flex;
            width: 48px;
            height: 48px;
            padding: 12px;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            border-color: transparent;
            background: white;
            box-shadow: 0px 4px 8px 0px rgba(73, 92, 136, 0.15);

            &:hover {
                background-color: var(--color-green-hover);
            }
        }

        .exchange-modal-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: center;
            margin: 0;
            text-align: left;
            color: var(--color-font);
            font-family: var(--font-primary);
            padding-top: 8px;
            padding-bottom: 32px;
        }
    }

    .exchange-modal-bottom {
        display: flex;
        flex-direction: column;

        .exchange-modal-container {
            display: flex;
            flex-direction: column;
            padding-top: 16px;
            padding-bottom: 16px;
            width: calc(100% - 16px - 16px);

            .exchange-modal-sub-title {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
                margin: 0;
                display: flex;
                justify-content: left;
                color: var(--color-font);
                font-family: var(--font-primary);
            }
        }
    }
}
