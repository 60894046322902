.tab-present {
    align-items: self-start;
    width: calc(100% - 64px - 64px);
    display: flex;
    flex-direction: column;
    padding: 58px 64px 48px 64px;

    @media (max-width: 576px) {
        padding: 0px 16px 16px 16px;
        width: calc(100% - 16px - 16px);
    }

    .title-text-h2 {
        text-align: left;
        margin-top: 16px;
        margin: 0;
    }

    .title-text-h3 {
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;

        @media (max-width: 576px) {
            font-size: 18px;
        }
    }

    .desc-text {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-font);
        font-family: var(--font-primary);
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .first-container {
        display: flex;
        flex-direction: row;
        gap: 32px;

        @media (max-width: 576px) {
            flex-direction: column-reverse;
            gap: 0px;
        }
    }

    .card-container {
        flex: 1;
        padding: 32px;
        background: white;
        border-radius: 24px;
        margin-top: 32px;
        width: calc(100% - 30px - 30px);

        @media (max-width: 576px) {
            padding: 32px 16px 32px 16px;
            width: calc(100% - 16px - 16px);
        }
    }

    .without-top-margin {
        margin-top: 16px;
    }

    .risk-container {
        display: flex;
        flex-direction: row;
        margin-top: 32px;

        .left-text {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--color-font);
            font-family: var(--font-primary);
            margin: 0;

            @media (max-width: 576px) {
                font-size: 11px;
            }
        }

        .right-text {
            text-align: right;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--color-font);
            font-family: var(--font-primary);
            margin: 0;

            @media (max-width: 576px) {
                font-size: 11px;
            }
        }
    }

    .risk-image {
        margin-top: 32px;
        max-width: 100%;
        height: auto;
        width: auto;
    }

    .year-image {
        margin-top: 16px;
        max-width: 100%;
        height: auto;
        width: auto;
    }

    .performance-container-top {
        display: flex;
        flex-direction: row;
        height: 34px;
        gap: 2px;
        margin-top: 30px;

        .inner-text {
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            color: white;
            font-family: var(--font-primary);
            padding-left: 2px;
            padding-right: 2px;

            @media (max-width: 800px) {
                font-size: 13px;
            }

            @media (max-width: 576px) {
                font-size: 10px;
            }
        }

        .performance-container-first-div {
            flex: 1;
            background: var(--color-gray);
            min-width: 147px;

            @media (max-width: 800px) {
                display: none;
            }
        }

        .performance-container-second-div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--color-green-main);
        }
    }

    .performance-container-bottom {
        display: flex;
        flex-direction: row;
        height: 34px;
        gap: 2px;
        margin-top: 0px;

        .inner-text {
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            color: var(--color-font);
            font-family: var(--font-primary);
            padding-left: 2px;
            padding-right: 2px;

            @media (max-width: 800px) {
                font-size: 13px;
            }

            @media (max-width: 576px) {
                font-size: 10px;
            }

            &.bg {
                margin-top: 28px;
            }
        }

        .performance-container-first-div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 147px;

            @media (max-width: 800px) {
                display: none;
            }
        }

        .performance-container-second-div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .apexcharts-xaxis-label {
        transform: translateX(0px);
        //display: none;

        // &:nth-child(30n-1) {
        //     display: revert;
        //     transform: translateX(-8px);
        // }

        // &:last-child:before {
        //     display: none;
        // }

        &:last-child {
            display: revert;
            transform: translateX(0px);
        }
    }
}
