.page-cookies {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -96px;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        @media (max-width: 576px) {
            width: calc(100% - 0px);
        }

        .content-card {
            border-radius: 32px;
            background: var(--color-primary);
            width: 100%;
            margin-bottom: 64px;

            .content-container {
                padding: 64px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: calc(100% - 64px - 64px);

                @media (max-width: 720px) {
                    font-size: 16px;
                    width: calc(100% - 24px - 24px);
                    padding-left: 24px;
                    padding-top: 32px;
                    padding-bottom: 48px;
                }

                ó .content-simple-text {
                    line-height: 24px;
                    font-weight: 400;
                }
                .content-title {
                    font-size: 20px;
                    text-align: left;
                    margin-top: 16px;
                    margin-bottom: 8px;
                }
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        margin-top: 8px;
                        white-space: normal;
                        float: left;
                        width: 100%;
                        word-wrap: break-word;
                        margin-left: 1em;
                        width: calc(100% - 1em);
                        margin-bottom: 8px;
                    }
                }
                ul li::before {
                    color: var(--color-green-main);
                    content: '\2022';
                    font-family: var(--font-primary);
                    font-weight: bold;
                    display: inline-block;
                    margin-left: -1em;
                    width: 1em;
                }

                .table-container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    margin-top: 16px;
                    height: 311px;
                    border-radius: 24px 24px 32px 32px;
                    width: 100%;
                    background: var(--color-background-gray);
                    margin-bottom: 16px;
                    @media (max-width: 996px) {
                        height: 325px;
                    }
                    @media (max-width: 576px) {
                        flex-wrap: wrap;
                        font-size: 16px;
                    }

                    .table-content {
                        padding-top: 24px;
                        width: calc(100% - 24px - 24px);
                        background: var(--color-grey-shader);

                        .table-content-header {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: var(--color-white-grey);
                            padding: 16px;
                            &.first {
                                border-radius: 16px 0 0 16px;
                                margin-left: 24px;
                                @media (max-width: 576px) {
                                    border-radius: 32px;
                                }
                            }
                            &.last {
                                border-radius: 0 16px 16px 0;
                                margin-right: 24px;
                                @media (max-width: 576px) {
                                    border-radius: 32px;
                                }
                            }
                            @media (max-width: 996px) {
                                height: 40px;
                            }
                            @media (max-width: 720px) {
                                font-size: 16px;
                            }
                            @media (max-width: 576px) {
                                justify-content: space-between;
                                border-radius: 32px;
                                width: calc(100% - 36px);
                                margin-left: 24px;
                                margin-right: 24px;
                                .pager-button {
                                    width: 48px;
                                    height: 48px;
                                    border-radius: 24px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid transparent;
                                    cursor: pointer;

                                    &.active {
                                        background-color: var(--color-green-main);
                                    }
                                    &.inactive {
                                        background-color: var(--color-inactive);
                                        cursor: not-allowed;
                                    }
                                    .inner-picture-back {
                                        width: 24px;
                                        height: 24px;
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-image: url('../../../public/image/otp_cookies_table_active_left_icon.svg');

                                        &.inactive-picture {
                                            background-image: url('../../../public/image/otp_cookies_table_left_icon.svg');
                                        }
                                    }
                                    .inner-picture-further {
                                        width: 24px;
                                        height: 24px;
                                        background-image: url('../../../public/image/otp_cookies_table_right_icon.svg');
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        &.inactive-picture {
                                            background-image: url('../../../public/image/otp_cookies_table_inactive_right_icon.svg');
                                        }
                                    }
                                }
                            }
                        }
                        .table-content-body {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: 400;
                            padding: 16px;
                            height: 80px;
                            align-self: stretch;
                            position: relative;
                            &.first {
                                margin-left: 24px;
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                width: 1px;
                                background-color: var(--color-border);
                                height: 75%;
                            }
                            &.last {
                                margin-right: 24px;
                                &::after {
                                    display: none;
                                }
                            }
                            .multitext-header {
                                @media (max-width: 720px) {
                                    font-size: 14px;
                                }
                                @media (max-width: 576px) {
                                    font-size: 16px;
                                }
                            }
                            @media (max-width: 720px) {
                                font-size: 14px;
                                padding: 4px;
                            }
                            @media (max-width: 576px) {
                                font-size: 16px;
                                padding: 16px;
                                justify-content: flex-start;
                                align-items: flex-start;
                                text-align: left;
                                width: calc(100% - 36px);
                                margin-left: 24px;
                                margin-right: 24px;

                                &::after {
                                    display: none;
                                }
                            }
                        }

                        .table-content-footer {
                            margin-left: 24px;
                            margin-right: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-right: 1px solid var(--color-border);
                            padding: 8px;
                            background: var(--color-white-grey);
                            height: 80px;
                            align-self: stretch;
                            @media (max-width: 576px) {
                                border: none;
                                width: calc(100% - 24px);
                                border-radius: 32px;
                                justify-content: flex-start;
                                flex-direction: row;
                            }
                            &.first {
                                border-radius: 16px 0 0 16px;
                                margin-right: 0px;
                                @media (max-width: 576px) {
                                    border-radius: 32px;
                                }
                            }
                            &.default {
                                margin-right: 0;
                                margin-left: 0;
                                @media (max-width: 576px) {
                                    margin-left: 24px;
                                    margin-right: 24px;
                                }
                            }
                            &.last {
                                border-radius: 0 16px 16px 0;
                                border-right: none;
                                margin-left: 0px;
                                @media (max-width: 576px) {
                                    border-radius: 32px;
                                    margin-left: 24px;
                                }
                            }
                            .footer-description {
                                font-weight: 400;
                                &.multitext {
                                    .footer-wrapper {
                                        display: flex;
                                        flex-direction: column;
                                        @media (max-width: 576px) {
                                            font-size: 16px;
                                            flex-direction: row;
                                            align-items: flex-start;
                                            justify-content: flex-start;
                                            flex-wrap: wrap;
                                        }
                                    }
                                    .multitext-header {
                                        @media (max-width: 720px) {
                                            font-size: 14px;
                                        }
                                        @media (max-width: 576px) {
                                            font-size: 16px;
                                        }
                                    }
                                }
                                @media (max-width: 720px) {
                                    font-size: 14px;
                                    padding: 4px;
                                }
                                @media (max-width: 576px) {
                                    font-size: 16px;
                                    padding: 16px;
                                }
                            }
                        }
                    }
                }

                .content-link {
                    color: var(--color-green-main);
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }
    }
}
