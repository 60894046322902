.header-side-menu {
    width: calc(100% - 24px - 24px);
    height: calc(548px - 24px - 24px);
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    position: absolute;
    padding: 24px 24px 24px 24px;
    background-color: white;

    &.hidden {
        display: none;
    }

    @media (min-width: 576px) {
        display: none;
    }

    .top-bar {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: space-between;

        .logo {
            height: 24px;
            max-width: 198px;
        }

        .language-menu-container {
            width: 100%;
            height: 24px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            column-gap: 17px;

            .menu-button {
                width: 24px;
                height: 100%;
                padding: 0;
                margin: 0;
                cursor: pointer;
                background-color: transparent;
                border-color: transparent;

                &:hover {
                    background-color: transparent;
                }
            }

            .language {
                height: 100%;
                color: var(--color-font);
                font-family: var(--font-primary);
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .exchange {
        width: 100%;
        padding: 0;
        margin-top: 24px;
        background-color: var(--color-green-main);
        border-radius: 32px;
        display: flex;
        flex-direction: row;

        .exchange-button {
            width: calc(100% - 56px - 1px);
            height: 48px;
            padding: 0;
            margin: 0;
            cursor: pointer;
            background-color: transparent;
            border-color: transparent;
            border: 1px solid transparent;
            border-top-left-radius: 32px;
            border-bottom-left-radius: 32px;
            font-family: var(--font-primary);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: start;
            padding-left: 24px;
            color: white;

            &:hover {
                background-color: var(--color-green-hover);
            }
        }

        .divider {
            width: 1px;
            height: 100%;
            background-color: white;
        }

        .arrow-button {
            width: 56px;
            height: 100%;
            padding: 0;
            margin: 0;
            cursor: pointer;
            background-color: transparent;
            border-color: transparent;
            border: 1px solid transparent;
            border-top-right-radius: 32px;
            border-bottom-right-radius: 32px;

            &:hover {
                background-color: var(--color-green-hover);
            }

            .arrow-icon {
                -moz-transition: all 0.2s linear;
                -webkit-transition: all 0.2s linear;
                transition: all 0.2s linear;
            }

            .rotate {
                -moz-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }

    .menu-bar {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 0;

        .menu-bar-item {
            cursor: pointer;
            width: 100%;
            height: 64px;
            display: flex;
            justify-content: left;
            align-items: flex-start;

            border: 0;
            border-top: 1px solid #eceff6;

            .title {
                font-size: 16px;
                line-height: 32px;
                font-weight: 700;
                height: 64px;
                margin: 0;
                display: flex;
                align-items: center;
                color: var(--color-font);
                font-family: var(--font-primary);
                text-decoration: none;

                &:hover {
                    color: var(--color-green-main);
                }

                &.active {
                    color: var(--color-green-main);
                }
            }
        }
    }
}
