@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: italic;
    font-weight: 100;
    src: url('../font/SourceSansPro-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: light;
    font-weight: 200;
    src: url('../font/SourceSansPro-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: italic;
    font-weight: 200;
    src: url('../font/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: light;
    font-weight: 300;
    src: url('../font/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    src: url('../font/SourceSansPro-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../font/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: bold;
    font-weight: 600;
    src: url('../font/SourceSansPro-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: italic;
    font-weight: 600;
    src: url('../font/SourceSansPro-SemiboldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: bold;
    font-weight: 700;
    src: url('../font/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: url('../font/SourceSansPro-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: bold;
    font-weight: 900;
    src: url('../font/SourceSansPro-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Source Sans Pro';
    font-style: italic;
    font-weight: 900;
    src: url('../font/SourceSansPro-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Squad';
    font-style: bold;
    font-weight: 900;
    src: url('../font/Squad-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Squad';
    font-style: bold;
    font-weight: 700;
    src: url('../font/Squad-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OTP Squad';
    font-style: normal;
    font-weight: 400;
    src: url('../font/Squad-Regular.ttf') format('truetype');
}
