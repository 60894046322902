.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    z-index: 16;
    gap: 0px;

    &.hidden {
        display: none;
    }

    .header-container {
        display: flex;
        padding: 16px 8px 0px 24px;
        background: var(--color-primary);
        width: calc(100% - 32px);

        .modal-title {
            display: flex;
            padding: 8px 0px 24px 0px;
            align-items: flex-start;
            flex: 1 0 0;

            span {
                color: var(--color-dark);
                font-family: var(--font-primary);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
            }
        }

        .modal-close-button {
            border: none;
            display: flex;
            width: 48px;
            height: 48px;
            padding: 12px;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            background: var(--color-primary);
            box-shadow: 0px 4px 8px 0px rgba(73, 92, 136, 0.15);
        }
    }

    .descriptionn-container {
        background: var(--color-primary);
        display: flex;
        height: fit-content;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 0px 24px 24px 24px;
        margin: 0;

        span {
            color: var(--color-dark);
            font-family: var(--font-primary);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        button {
            font-family: var(--font-primary);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            min-width: 128px;
            margin-bottom: 20px;
            margin-top: 12px;
        }

        .hidden {
            display: none;
        }
    }
}
