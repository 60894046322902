.information {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;

    @media (max-width: 576px) {
        width: calc(100% - 24px - 24px);
        padding-left: 24px;
        padding-right: 24px;
    }

    .infomation-title {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        padding: 0;
        margin: 0;
        color: var(--color-font);
        font-family: var(--font-primary);
    }

    .infomation-description {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding: 0;
        margin: 0;
        margin-top: 8px;
        color: var(--color-font);
        font-family: var(--font-primary);
    }

    .info-container {
        width: calc(100%);
        display: flex;
        gap: 16px;
        margin-top: 64px;
        margin-bottom: 58px;

        @media (max-width: 576px) {
            margin-top: 48px;

            display: flex;
            flex-direction: column;
        }

        .item {
            display: flex;
            flex-direction: column;
            flex: 1 1 0;
            align-items: center;
            justify-content: center;

            .text {
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                padding: 0;
                margin: 0;
                color: var(--color-font);
                font-family: var(--font-primary);
                text-decoration: none;
            }

            .icon {
                margin-bottom: 32px;
                height: 72px;

                @media (max-width: 576px) {
                    height: 46px;
                }
            }

            &.first {
                background-image: url('../../../public/image/otp_multi_asset_konzervativ_alapok_alapja_footer_card_logo.png');
            }

            &.second {
                background-image: url('../../../public/image/otp_multi_asset_kiegyensulyozott_alapok_alapja_footer_card_logo.png');
            }

            &.third {
                background-image: url('../../../public/image/otp_multi_asset_dinamius_alapok_alapja_footer_card_logo.png');
            }
        }
    }
}
