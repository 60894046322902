.page-managers {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        .top-container {
            width: 100%;
            margin-top: 64px;
            margin-bottom: 64px;
            display: flex;
            flex-direction: column;
            gap: 88px;

            @media (max-width: 1120px) {
                width: calc(100% - 8px - 8px);
                padding-left: 8px;
                padding-right: 8px;
            }

            @media (max-width: 576px) {
                margin-top: 32px;
                margin-bottom: 0;
                width: calc(100% - 0px - 0px);
                padding-left: 0px;
                padding-right: 0px;
            }

            .title-text {
                font-size: 32px;
                font-weight: 900;
                text-align: left;
                line-height: 40px;
                margin: 0;
                color: var(--color-font);
                font-family: var(--font-primary);
                margin-bottom: 8px;
                padding: 0;
            }

            .sub-title-text {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                margin: 0;
                color: var(--color-font);
                font-family: var(--font-primary);
            }

            .description-text {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: var(--color-font);
                font-family: var(--font-primary);
                margin-top: 16px;
            }

            .leader-container {
                width: calc(100% - 64px - 64px);
                display: flex;
                border-radius: 32px;
                box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);
                padding: 64px 64px 0px 64px;
                gap: 32px;
                background-color: white;

                @media (max-width: 1120px) {
                    width: calc(100% - 32px - 32px);
                    padding-left: 32px;
                    padding-right: 32px;
                }

                @media (max-width: 916px) {
                    flex-direction: column;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 24px;
                    width: calc(100% - 16px - 16px - 16px);
                    padding: 32px 16px 32px 16px;
                    border-radius: 24px;
                    margin-left: 8px;
                    margin-right: 8px;
                }

                .profile-image {
                    width: 100%;
                    max-height: 465px;
                    min-width: 338px;
                    border-radius: 24px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-position: center;
                    background-image: url('../../../public/image/czachesz_gabor.png');

                    @media (max-width: 916px) {
                        min-height: 465px;

                        max-width: 338px;
                    }

                    @media (max-width: 576px) {
                        min-height: calc(195px + 32px);
                        max-width: calc(100% + 32px);
                        width: calc(100% + 32px);
                        margin-top: -32px;
                        margin-left: -16px;
                        margin-right: -16px;
                        border-radius: 0px;

                        border-top-left-radius: 24px;
                        border-top-right-radius: 24px;

                        background-image: url('../../../public/image/czachesz_gabor_mobile.png');
                    }
                }

                .content-container {
                    display: flex;
                    flex-direction: column;
                    margin-right: 46px;

                    @media (max-width: 1120px) {
                        margin-right: 0;
                    }

                    @media (max-width: 576px) {
                        margin-right: 0px;
                    }

                    .bottom-content-container {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        @media (max-width: 576px) {
                            display: none;
                        }

                        .badge-container {
                            width: calc(100%);
                            display: flex;
                            gap: 32px;
                            margin-top: 32px;

                            @media (max-width: 810px) {
                                gap: 16px;
                            }

                            .badge {
                                display: flex;
                                flex-direction: column;
                                flex: 1;
                                border-radius: 24px;
                                background-color: var(--color-background-gray);
                                align-items: center;
                                justify-content: center;
                                padding: 26px 8px 26px 8px;

                                .icon {
                                    margin-bottom: 14px;
                                    height: 66px;
                                }
                            }
                        }

                        .source-text {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: right;
                            margin: 20px 0px;
                            color: var(--color-font);
                            font-family: var(--font-primary);
                            margin-right: -46px;

                            @media (max-width: 1120px) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .coworker-container {
                width: calc(100% - 64px - 64px);
                display: flex;
                border-radius: 32px;
                box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);
                padding: 64px;
                background-color: white;
                align-items: center;
                flex-direction: column;

                @media (max-width: 1120px) {
                    width: calc(100% - 32px - 32px);
                    padding-left: 32px;
                    padding-right: 32px;
                }

                @media (max-width: 916px) {
                    flex-direction: column;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 24px;
                    width: calc(100%);
                    padding: 0;
                    background-color: transparent;
                    box-shadow: none;
                    margin-bottom: 48px;
                }

                .coworker-profile-container {
                    width: calc(100%);
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 32px;

                    &.top-margin {
                        margin-top: 64px;
                    }

                    @media (max-width: 1056px) {
                        flex-direction: column;
                    }

                    .profile-card {
                        flex: 50%;

                        .title-text-h2 {
                            margin-bottom: 8px;
                        }

                        .title-text-h3 {
                            text-align: center;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            font-style: normal;

                            margin: 0;
                            color: var(--color-font);
                            font-family: var(--font-primary);
                        }
                    }
                }
            }
        }

        .middle-container {
            position: relative;

            .middle-container-bg {
                width: calc(100vw - 15px);
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 100%;
                transform: translate(calc(1120px / 2 - 50%), 0);
                background: var(--color-gray-shader);

                &.full-width {
                    width: 100vw;
                }

                @media (max-width: 1120px) {
                    transform: translate(0, 0);
                }

                @media (max-width: 576px) {
                    width: 100vw;
                }
            }

            .middle-container-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 64px;
                margin-bottom: 64px;
                position: relative;

                @media (max-width: 1120px) {
                    width: calc(100% - 8px - 8px);
                    padding-left: 8px;
                    padding-right: 8px;
                }

                @media (max-width: 852px) {
                    width: calc(100% - 0px - 0px);
                    padding-left: 0px;
                    padding-right: 0px;
                    margin-top: 48px;
                }

                .founds-container {
                    width: calc(100%);
                    display: flex;
                    gap: 32px;
                    margin-top: 65px;

                    @media (max-width: 920px) {
                        gap: 16px;
                    }

                    @media (max-width: 852px) {
                        margin-top: 40px;
                    }
                }
            }
        }

        .bottom-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 64px;
            margin-bottom: 64px;
            position: relative;

            @media (max-width: 1120px) {
                width: calc(100% - 8px - 8px);
                padding-left: 8px;
                padding-right: 8px;
            }

            @media (max-width: 852px) {
                width: calc(100% - 0px - 0px);
                padding-left: 0px;
                padding-right: 0px;
                margin-top: 48px;
            }

            .afterword {
                display: flex;
                max-width: 657px;
                justify-content: center;
                margin-bottom: 24px;
                margin-left: 24px;
                margin-right: 24px;
                padding: 0;

                h3 {
                    color: var(--color-font);
                    text-align: center;
                    font-family: var(--font-primary);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    padding: 0;
                    margin: 0;

                    @media (max-width: 754px) {
                        font-size: 18px;
                    }
                }
            }

            .middle-container-button-link {
                color: var(--color-primary);
                text-decoration: none;
            }
        }
    }
}
