.tab-about {
    align-items: self-start;
    width: calc(100% - 64px - 64px);
    display: flex;
    flex-direction: column;
    padding: 48px 64px 48px 64px;

    @media (max-width: 576px) {
        width: calc(100% - 16px - 16px);
        padding: 0px 16px 16px 16px;
    }

    .title-text-h2 {
        text-align: left;
        margin-top: 16px;
    }

    .desc-text {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-font);
        font-family: var(--font-primary);
        margin-top: 16px;
        margin-bottom: 16px;
    }
}
