.exchange-menu {
    width: calc(100% - 24px - 24px);
    max-width: var(--page-width);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 96px;

    &.hidden {
        display: none;
    }

    @media (max-width: 576px) {
        display: none;
    }

    .bubble {
        height: 16px;
        width: 24px;
        margin-right: 112px;
    }

    .exchange-menu-container {
        width: calc(100% - 34px - 34px);
        display: flex;
        gap: 32px;
        padding: 32px 34px 32px 34px;
        background-color: white;
        border-radius: 32px;

        @media (max-width: 810px) {
            gap: 16px;
            width: calc(100% - 16px - 16px);
            padding: 32px 16px 32px 16px;
        }

        .item {
            display: flex;
            flex-direction: column;
            flex: 1 1 0;

            .top {
                display: flex;
                background-color: var(--color-conservative-green);
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;
                display: flex;
                align-items: center;
            }

            .bottom {
                height: 100%;
                display: flex;
                flex-direction: column;
                background-color: var(--color-background-gray);
                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
                padding-left: 16px;
                padding-right: 16px;
                width: calc(100% - 16px - 16px);

                .container {
                    display: flex;
                    flex-direction: column;
                    border-radius: 16px;
                    padding: 16px 0px;
                    width: calc(100%);

                    @media (max-width: 810px) {
                        font-size: 8px;
                    }
                }
            }

            .title {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
                margin: 0;
                max-width: calc(100% - 43px - 43px);
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--color-primary);
                font-family: var(--font-primary);
                text-decoration: none;
                padding: 16px 43px 16px 43px;

                @media (max-width: 810px) {
                    max-width: calc(100% - 16px - 16px);
                    font-size: 14px;

                    padding: 16px 16px 16px 16px;
                }
            }

            .sub-title {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
                margin: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--color-font);
                font-family: var(--font-primary);
                text-decoration: none;

                @media (max-width: 810px) {
                    font-size: 14px;
                }
            }
        }
    }
}
