.footer-card-container {
    width: 100%;
    height: calc(100% - 64px);
    margin-bottom: 96px;
    display: flex;
    flex-direction: row;
    gap: 34px;
    padding: 0;
    z-index: 1;
    position: relative;

    @media (max-width: 1120px) {
        width: calc(100% - 8px - 8px);
        margin-right: 8px;
        margin-left: 8px;
    }

    @media (max-width: 810px) {
        gap: 16px;
        z-index: 0;
    }

    @media (max-width: 576px) {
        flex-direction: column;
        gap: 24px;
        height: 100%;
        height: calc(100% - 48px);
        margin-bottom: 48px;
        z-index: 10;
    }

    .footer-card-button {
        color: var(--color-primary);
        text-align: center;
        border-radius: 24px;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center;
        height: 128px;
        width: calc(100% - 32px - 32px);
        padding-left: 32px;
        padding-right: 32px;
        align-items: center;
        display: flex;

        @media (max-width: 1100px) {
            padding-left: 8px;
            padding-right: 8px;
            width: calc(100% - 8px - 8px);
        }

        @media (max-width: 576) {
            padding-left: 0px;
            padding-right: 0px;
            width: 100%;
        }

        &.disabled {
            border-radius: 24px;
            opacity: 0.6000000238418579;
            background: var(--color-primary);
        }

        &:hover {
            background-color: transparent;
        }

        &.first {
            background-image: url('../../../../public/image/otp_multi_asset_konzervativ_alapok_alapja_footer_card_logo.png');
        }

        &.second {
            background-image: url('../../../../public/image/otp_multi_asset_kiegyensulyozott_alapok_alapja_footer_card_logo.png');
        }

        &.third {
            background-image: url('../../../../public/image/otp_multi_asset_dinamius_alapok_alapja_footer_card_logo.png');
        }

        &.hidden {
            display: none;
        }

        .footer-link {
            color: var(--color-primary);
            text-align: center;
            font-family: var(--font-primary);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            text-decoration-line: underline;
            width: 100%;
            white-space: pre-wrap;

            @media (max-width: 1100px) {
                font-size: 18px;
            }

            @media (max-width: 576) {
                font-size: 20px;
            }
        }
    }
}
