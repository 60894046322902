.floating-exchange-menu {
    width: calc(100% - 24px - 24px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 121px;
    left: 24px;
    position: absolute;
    box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);
    border-radius: 16px;
    background-color: white;
    z-index: 12;

    &.hidden {
        display: none;
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: var(--color-gray-shader);
    }

    .floating-exchange-menu-title {
        height: calc(100% - 8px - 8px);
        width: calc(100% - 24px - 24px);
        padding: 8px 24px 8px 24px;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: var(--color-font);
        font-family: var(--font-primary);

        &:hover {
            color: var(--color-green-main);
        }

        &.active {
            color: var(--color-green-main);
        }
    }
}
