.header {
    align-items: center;
    background-color: var(--color-light-green);
    display: flex;
    width: 100%;
    height: 600px;
    justify-content: center;
    position: relative;
    padding: 0;

    @media (max-width: 576px) {
        height: 548px;
        z-index: 0;
    }

    &.header-home-background {
        background-color: var(--color-blue);

        @media (max-width: 576px) {
            background-color: var(--color-blue-dark);
        }
    }

    .header-parent-layer {
        .header-bg-layer {
            width: var(--page-width);
            height: 100%;
            z-index: 1;
            overflow: clip;

            @media (max-width: 576px) {
                width: 100%;
            }

            .logo-crop {
                height: 233px;
                width: 497px;
                left: calc(50% - 497px - 150px);
                position: absolute;
                top: 372px;

                @media (max-width: 576px) {
                    display: none;
                }

                &.hidden {
                    display: none;
                }
            }

            .logo-crop-bottom {
                height: 344px;
                width: 497px;
                left: calc(50% - 497px - 150px);
                position: absolute;
                top: 256px;

                @media (max-width: 576px) {
                    display: none;
                }

                &.hidden {
                    display: none;
                }
            }

            .video-layer {
                width: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 100%;
                overflow: clip;

                .home-background {
                    height: 600px;
                    width: 1920px;
                    left: calc(50% - 960px);
                    position: absolute;
                    top: 0px;
                    overflow-y: hidden;

                    @media (max-width: 576px) {
                        display: none;
                    }

                    &.hidden {
                        display: none !important;
                    }
                }
            }

            .home-background-mobile {
                height: 478px;
                width: 320px;
                right: 0px;
                position: absolute;
                top: 0px;

                @media (min-width: 576px) {
                    display: none;
                }

                &.hidden {
                    display: none !important;
                }
            }
        }

        .header-front-layer {
            align-items: center;
            display: flex;
            left: 0;
            padding: 49px 24px 49px 24px;
            position: absolute;
            top: 0;
            width: calc(100% - 24px - 24px);
            height: calc(600px - 49px - 49px);
            justify-content: center;
            z-index: 1;

            @media (max-width: 576px) {
                padding: 24px 24px 24px 24px;
                height: calc(548px - 24px - 24px);
            }

            .container {
                max-width: var(--page-width);
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 0;
                margin: 0;

                &.hidden {
                    display: none !important;
                }

                &.menu-container {
                    width: calc(100% - 24px - 24px);
                    height: calc(478px - 24px - 24px);
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    padding: 24px 24px 24px 24px;
                    background-color: bisque;
                }

                .top-bar {
                    height: 48px;
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: 576px) {
                        height: 24px;
                    }

                    .logo {
                        height: 48px;
                        max-width: 198px;

                        @media (max-width: 576px) {
                            height: 24px;
                        }
                    }

                    .logo-en {
                        height: 48px;
                        max-width: 280px;

                        @media (max-width: 576px) {
                            height: 23px;
                        }
                    }

                    .exchange-language-container {
                        width: 100%;
                        height: 48px;
                        display: flex;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        column-gap: 52px;

                        @media (max-width: 576px) {
                            display: none;
                        }

                        .exchange {
                            width: 202px;
                            height: 100%;
                            background-color: var(--color-green-main);
                            border-radius: 32px;
                            display: flex;
                            flex-direction: row;

                            position: relative;

                            .exchange-button {
                                width: calc(100% - 56px - 1px);
                                height: 100%;
                                padding: 0;
                                margin: 0;
                                cursor: pointer;
                                background-color: transparent;
                                border-color: transparent;
                                border: 1px solid transparent;
                                border-top-left-radius: 32px;
                                border-bottom-left-radius: 32px;
                                font-family: var(--font-primary);
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 24px;
                                letter-spacing: 0px;
                                color: white;

                                &:hover {
                                    background-color: var(--color-green-hover);
                                }
                            }

                            .divider {
                                width: 1px;
                                height: 100%;
                                background-color: white;
                            }

                            .arrow-button {
                                width: 56px;
                                height: 100%;
                                padding: 0;
                                margin: 0;
                                cursor: pointer;
                                background-color: transparent;
                                border-color: transparent;
                                border: 1px solid transparent;
                                border-top-right-radius: 32px;
                                border-bottom-right-radius: 32px;

                                &:hover {
                                    background-color: var(--color-green-hover);
                                }

                                .arrow-icon {
                                    -moz-transition: all 0.2s linear;
                                    -webkit-transition: all 0.2s linear;
                                    transition: all 0.2s linear;
                                }

                                .rotate {
                                    -moz-transform: rotate(180deg);
                                    -webkit-transform: rotate(180deg);
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .language {
                            height: 100%;
                            text-decoration: underline;
                            color: var(--color-white);
                            font-family: var(--font-primary);
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .language-menu-container {
                        width: 100%;
                        height: 24px;
                        display: flex;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        column-gap: 17px;

                        @media (min-width: 576px) {
                            display: none;
                        }

                        .menu-button {
                            width: 24px;
                            height: 100%;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            background-color: transparent;
                            border-color: transparent;

                            &:hover {
                                background-color: transparent;
                            }
                        }

                        .language {
                            height: 100%;
                            color: var(--color-white);
                            font-family: var(--font-primary);
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 16px;
                            letter-spacing: 0px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }

                .menu-bar {
                    width: calc(100%);
                    height: 64px;
                    margin-top: 24px;
                    margin-bottom: 0px;
                    background-color: var(--color-white);
                    border: 0px solid transparent;
                    border-radius: 32px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    list-style-type: none;
                    padding: 0;

                    @media (max-width: 576px) {
                        display: none;
                    }

                    .menu-bar-item {
                        cursor: pointer;
                        width: 100%;
                        height: 64px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .title {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0px;
                            margin: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--color-font);
                            font-family: var(--font-primary);
                            text-decoration: none;

                            &.left {
                                padding-left: 40px;
                                justify-content: left;
                            }

                            &.right {
                                padding-right: 40px;
                                justify-content: right;
                            }

                            &:hover {
                                color: var(--color-green-main);
                            }

                            &.active {
                                color: var(--color-green-main);
                            }
                        }
                    }
                }

                .breadcrumb {
                    margin-top: 32px;
                    width: 100%;
                    height: 16px;
                    padding: 0;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    column-gap: 8px;

                    @media (max-width: 576px) {
                        display: none;
                    }

                    .home {
                        height: 16px;
                        max-width: 16px;
                    }

                    .arrow {
                        height: 16px;
                        max-width: 16px;
                    }

                    .page-title {
                        height: 100%;
                        text-decoration: underline;
                        color: var(--color-white);
                        font-family: var(--font-primary);
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0px;
                        display: flex;
                        align-items: center;

                        &.active {
                            cursor: pointer;
                        }

                        &.sub-title {
                            text-decoration: none;
                            font-weight: 400;
                        }
                    }
                }

                .text-container {
                    margin-top: 84px;
                    width: 50%;
                    height: 100%;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &.bulgarian {
                        width: 56%;
                    }

                    &.small-margin {
                        margin-top: 0px;

                        .label-text {
                            margin-top: 8px;
                            margin-bottom: 40px;
                        }
                    }

                    @media (max-width: 1120px) {
                        width: 60%;
                    }

                    @media (max-width: 900px) {
                        width: 80%;
                    }

                    @media (max-width: 576px) {
                        width: 80%;
                        margin-top: 32px;

                        justify-content: flex-end;
                    }

                    @media (max-width: 420px) {
                        width: 100%;
                    }

                    .header-text {
                        font-style: normal;

                        @media (max-width: 576px) {
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: 40px;
                        }
                    }

                    .label-text {
                        margin: 0;
                        margin-top: 20px;
                        margin-bottom: 22px;
                        letter-spacing: -0.1px;
                        line-height: 32px;

                        &.small-margin {
                            margin-bottom: 96px;
                        }

                        @media (max-width: 1120px) {
                            font-size: 22px;
                        }

                        @media (max-width: 900px) {
                            font-size: 20px;
                            margin-top: 10px;
                        }

                        @media (max-width: 576px) {
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 24px;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}
