.profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 576px) {
        flex-direction: column;
        gap: 24px;
        width: calc(100%);
        padding: 0;
        padding-bottom: 32px;
        box-shadow: 0px 16px 18px 0px rgba(73, 92, 136, 0.15);
        background-color: white;
        border-radius: 24px;
        min-height: 352px;
    }

    .title-text-h1 {
        margin-bottom: 32px;

        @media (max-width: 576px) {
            white-space: pre-wrap;
        }
    }

    .halas-profile-image {
        width: 100%;
        min-height: 312px;
        min-width: 465px;
        border-radius: 24px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center;
        background-image: url('../../../../public/image/halas_zoltan.png');
        margin-bottom: 32px;

        &.czako-profile-image {
            background-image: url('../../../../public/image/czako_agnes.png');
        }

        &.gajdacs-profile-image {
            background-image: url('../../../../public/image/gajdacs_attila.png');
        }

        &.heinz-profile-image {
            background-image: url('../../../../public/image/heinz_frigyes.png');
        }

        @media (max-width: 1056px) {
            min-height: 312px;
            max-width: 465px;
        }

        @media (max-width: 576px) {
            min-height: 185px;
            max-width: 100%;
            min-width: 100%;
            max-height: 185px;
            border-radius: 0px;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            background-image: url('../../../../public/image/halas_zoltan.png');

            &.czako-profile-image {
                background-image: url('../../../../public/image/czako_agnes.png');
            }

            &.gajdacs-profile-image {
                background-image: url('../../../../public/image/gajdacs_attila.png');
            }

            &.heinz-profile-image {
                background-image: url('../../../../public/image/heinz_frigyes_mobile.png');
            }
        }
    }

    .title-text-h3 {
        @media (max-width: 576px) {
            max-width: 180px;
            white-space: pre-wrap;
        }
    }

    .button {
        margin-top: 24px;
    }
}
