.modal-container {
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: rgba(21, 22, 23, 0.5);
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 20;

    .modal {
        background-color: var(--color-white);
        border-radius: 32px;
        overflow-y: scroll;
        padding: 32px;
        width: calc(544px - 32px - 32px);
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 576px) {
            height: 100vh;
            width: 100vw;
            width: calc(100vw - 24px - 24px);
            border-radius: 0px;
            padding: 24px;
            justify-content: start;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__title {
            color: var(--color-font);
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: left;
            align-items: center;
        }

        &__message {
            color: var(--color-font);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            width: 100%;
            text-align: left;
        }

        .close-button {
            background-color: transparent;
            border: 1px solid transparent;
            border-radius: 24px;
            line-height: 18px;
            cursor: pointer;
            width: 48px;
            height: 48px;
            padding: 12px;
            box-shadow: 0px 4px 8px 0px rgba(73, 92, 136, 0.15);
            transition: background-color var(--transition-speed);

            &:hover {
                background-color: var(--color-highlight);
            }
        }

        &__footer {
            display: flex;
            justify-content: right;
            align-items: right;
        }
    }
}
