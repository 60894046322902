.footer {
    align-items: center;
    background-color: var(--color-green-main);
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    position: relative;
    padding: 0;
    border-radius: 40px 40px 0px 0px;

    @media (max-width: 620px) {
        height: calc(272px - 32px - 32px);
        width: calc(100% - 24px - 24px);
        padding: 32px 24px 32px 24px;
    }

    .container {
        width: var(--page-width);
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;

        @media (max-width: (1120px + 24px + 24px)) {
            width: calc(var(--page-width) - 24px - 24px);

            padding-left: 24px;
            padding-right: 24px;
        }

        @media (max-width: 620px) {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 0;
            padding-right: 0;
        }

        .logo {
            height: 24px;
            width: 147px;
            margin-top: 48px;

            @media (max-width: 620px) {
                margin-top: 0;
            }
        }

        .logo-en {
            height: 24px;
            max-width: 207px;
            margin-top: 48px;

            @media (max-width: 620px) {
                margin-top: 0;
            }
        }

        .text-container {
            margin-top: 48px;
            margin-left: 45px;
            width: 100%;
            margin-bottom: 0px;
            display: flex;
            flex-direction: row;
            padding: 0;
            gap: 24px;

            @media (max-width: 620px) {
                flex-direction: column;
                gap: 16px;
                margin-top: 24px;
                margin-left: 0;
            }

            .title {
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                margin: 0;
                margin-top: 4px;

                color: var(--color-primary);
                font-family: var(--font-primary);
                text-decoration: none;

                &:hover {
                    color: var(--color-light-green);
                }

                @media (max-width: 620px) {
                    text-align: left;
                }
            }

            .copyright-container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding: 0;
                flex: 1;
                min-width: 120px;

                @media (max-width: 620px) {
                    margin-top: 8px;
                    align-items: flex-start;
                }

                .copyright-title {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: right;
                    margin: 0;
                    color: var(--color-primary);
                    font-family: var(--font-primary);
                }

                .powered-by-title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: right;
                    margin: 0;
                    color: var(--color-primary);
                    font-family: var(--font-primary);
                }
            }
        }
    }
}
