.language-menu {
    min-width: calc(143px - 8px - 8px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 240px;
    left: calc(50% + 408px);

    position: absolute;
    z-index: 1;

    &.hidden {
        display: none;
    }

    &:hover {
        display: flex;
    }

    .bubble {
        height: 16px;
        width: 24px;
        margin-left: calc(120px);
    }

    .floating-menu-bar {
        width: 100%;
        margin: 0px;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 8px;
        background-color: white;
        box-shadow: 0px 16px 48px 0px rgba(73, 92, 136, 0.15);
        border-radius: 16px;

        .floating-menu-bar-item {
            cursor: pointer;
            width: 100%;
            height: 32px;
            display: flex;
            padding: 0;
            flex-direction: column;
            align-items: flex-start;

            .floating-menu-bar-item-title {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                height: 100%;
                display: flex;
                padding-left: 16px;
                padding-right: 16px;

                align-items: center;
                color: var(--color-green-main);
                font-family: var(--font-primary);
                text-decoration: none;

                &:hover {
                    color: var(--color-light-green);
                }

                &.active {
                    color: var(--color-light-green);
                }
            }
        }
    }
}
