.text-area {
    width: 100%;

    .area--text {
        width: calc(100%);
        min-height: 48px;

        textarea {
            padding: 12px 24px;
            width: calc(100% - 24px - 24px);
            min-height: 48px;
            border: 1px solid #a6adba;
            border-radius: 24px;
            font-size: 16px;
            font-weight: 700;

            line-height: 24px;
            overflow: hidden;
            resize: none;
            color: var(--color-font);
            font-family: var(--font-primary);
            outline: none;

            &:focus {
                border: 2px solid #52ae30;
            }

            &::placeholder {
                color: var(--color-placeholder);
                font-weight: 400;
            }

            &[disabled] {
                opacity: 0.5;
            }
        }
    }

    .error-field {
        &.hidden {
            display: none;
        }

        .error-bubble {
            margin-left: 16px;
            width: 24px;
            height: 16px;
            margin-bottom: -3px;
        }

        .error-message {
            background-color: #fff0e6;
            border: 1px solid #fff0e6;
            border-radius: 16px;
            color: var(--color-font);
            font-family: var(--font-primary);
            outline: none;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            width: calc(100% - 24px - 24px);
            padding: 16px 24px;
            margin: 0;
        }
    }
}
