.text-field {
    width: 100%;

    .field {
        width: 100%;

        input {
            border: 1px solid #a6adba;
            border-radius: 24px;
            color: var(--color-font);
            font-family: var(--font-primary);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            outline: none;
            transition: border-color var(--transition-speed);
            width: calc(100% - 24px - 24px);
            height: calc(48px - 12px - 12px - 2px);
            padding: 12px 24px;
            margin: 0;

            &:focus {
                border: 2px solid #52ae30;
            }

            &::placeholder {
                color: var(--color-placeholder);
                font-weight: 400;
            }

            &[disabled] {
                opacity: 0.5;
            }
        }

        &--error {
            input {
                border-color: #ff435a;
            }
        }
    }

    .error-field {
        &.hidden {
            display: none;
        }

        .error-bubble {
            margin-left: 16px;
            width: 24px;
            height: 16px;
            margin-bottom: -3px;
        }

        .error-message {
            background-color: #fff0e6;
            border: 1px solid #fff0e6;
            border-radius: 16px;
            color: var(--color-font);
            font-family: var(--font-primary);
            outline: none;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            width: calc(100% - 24px - 24px);
            padding: 16px 24px;
            margin: 0;
        }
    }
}
