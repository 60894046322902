.page-impressum {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -96px;
    //margin-bottom: max(64px, 25vh);
    margin-bottom: 64px;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        @media (max-width: 576px) {
            width: calc(100% - 0px);
        }

        .content-card {
            border-radius: 32px;
            background: var(--color-primary);
            width: 100%;

            @media (max-width: 576px) {
                width: calc(100% - 0px);
            }

            .text-container {
                width: calc(100% - 62px - 62px);
                height: 100%;
                display: flex;
                align-items: center;
                margin-left: 62px;
                margin-right: 62px;
                margin-top: 64px;
                margin-bottom: 64px;

                @media (max-width: 576px) {
                    width: calc(100% - 25px - 23px);
                    margin-left: 24px;
                    margin-right: 24px;
                    margin-top: 32px;
                    margin-bottom: 48px;
                }

                ul {
                    list-style: none;
                    padding: 0;
                }

                li {
                    line-height: 24px;
                    font-weight: 400;
                    font-size: 16px;
                    color: var(--color-font);
                    font-family: var(--font-primary);
                }
            }
        }
    }
}
