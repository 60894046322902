.page-company {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        align-items: flex-start;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

        @media (max-width: 576px) {
            width: calc(100% - 0px);
        }

        .description-container {
            margin-top: 62px;
            flex-shrink: 0;
            margin-bottom: 61px;
            width: calc(100% - 16px);

            @media (max-width: 1120px) {
                width: calc(100% - 24px - 24px);
                padding-left: 24px;
                padding-right: 24px;
            }

            @media (max-width: 576px) {
                width: calc(100% - 24px - 24px);
                padding-left: 24px;
                padding-right: 24px;
                margin-top: 32px;
                margin-bottom: 48px;
            }

            .description-text {
                color: var(--color-font);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                @media (max-width: 866px) {
                    font-size: 14px;
                }

                sup {
                    font-size: 10px;
                    font-weight: 600;

                    @media (max-width: 866px) {
                        font-size: 8px;
                    }
                }

                .small-print {
                    margin-top: 22px;
                    font-size: 14px;
                    line-height: 24px;

                    @media (max-width: 866px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .content-card {
            border-radius: 32px;
            background: var(--color-gray-shader);
            height: fit-content;
            margin-bottom: 64px;
            height: calc(100% - 64px - 64px);
            padding: 64px;
            padding-bottom: 26px;

            @media (max-width: 1120px) {
                width: calc(100% - 32px - 32px);
                padding: 32px;
                gap: 16px;
            }

            @media (max-width: 576px) {
                width: calc(100% - 8px - 8px);
                padding-left: 8px;
                padding-right: 8px;
                padding-top: 48px;
            }

            .title-container {
                width: 100%;

                .title {
                    text-align: left;
                    margin-bottom: 32px;

                    @media (max-width: 866px) {
                        font-size: 28px;
                        margin-bottom: 18px;
                    }
                    @media (max-width: 576px) {
                        margin-left: 24px;
                        margin-right: 24px;
                        line-height: 32px;
                    }
                }
                .subtitle {
                    text-align: left;
                    margin-bottom: 32px;
                    @media (max-width: 866px) {
                        font-size: 24px;
                        margin-bottom: 18px;
                    }
                    @media (max-width: 576px) {
                        line-height: 32px;
                        margin-left: 24px;
                        margin-right: 24px;
                    }
                }
            }

            .reference-container {
                width: 100%;
                background-color: var(--color-primary);
                border-radius: 24px;
                display: flex;
                flex-direction: row;
                margin-bottom: 64px;
                width: calc(100% + 8px + 8px);
                padding-left: -8px;
                padding-right: -8px;

                @media (max-width: 576px) {
                    flex-direction: column;
                    background-color: var(--color-gray-shader);
                    width: calc(100% + 8px + 8px);
                    padding-left: -8px;
                    padding-right: -8px;
                    transform: translate(-8px, 0%);
                }

                .item {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    border-radius: 24px;
                    background-color: var(--color-primary);
                    align-items: center;
                    justify-content: center;
                    height: 192px;

                    @media (max-width: 866px) {
                        height: 140px;
                    }

                    @media (max-width: 576px) {
                        gap: 10px;
                        padding: 32px;
                        box-shadow: 0px 16px 18px 0px rgba(73, 92, 136, 0.15);
                        padding-left: 8px;
                        padding-right: 8px;
                    }

                    .icon {
                        margin-bottom: 18px;
                        height: 87px;

                        @media (max-width: 866px) {
                            height: 60px;
                            margin-bottom: 10px;
                        }

                        @media (max-width: 576px) {
                            height: 70px;
                            margin-bottom: 10px;
                        }
                    }

                    .label {
                        text-align: center;

                        @media (max-width: 866px) {
                            font-size: 12px;
                        }

                        @media (max-width: 576px) {
                            font-size: 16px;
                        }
                    }
                }
            }

            .milestone-space-container {
                margin-top: 100px;

                @media (max-width: 866px) {
                    margin-top: 46px;
                }

                @media (max-width: 576px) {
                    display: flex;
                    flex-direction: column;
                    margin-left: 24px;
                    margin-right: 24px;
                }

                .milestone-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 52px;
                    max-height: 48px;

                    @media (max-width: 866px) {
                        margin-bottom: 32px;
                        max-height: 28px;
                    }
                    @media (max-width: 576px) {
                        max-height: 32px;
                    }

                    .year-container {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 32px;
                        margin-right: 21px;

                        @media (max-width: 866px) {
                            font-size: 16px;
                        }
                    }

                    .green-circle {
                        height: 48px;
                        width: 48px;
                        border-radius: 24px;
                        background-color: var(--color-green-main);
                        margin-right: 15px;
                        text-align: center;
                        position: relative;

                        .green-line {
                            position: absolute;
                            bottom: 47px;
                            transform: translate(-50%, 0%);

                            @media (max-width: 866px) {
                                height: 520px;
                                bottom: 27px;
                            }

                            @media (max-width: 576px) {
                                height: 540px;
                                bottom: 30px;
                            }
                        }

                        @media (max-width: 866px) {
                            height: 28px;
                            width: 28px;
                            border-radius: 14px;
                        }

                        @media (max-width: 576px) {
                            width: 31.937px;
                            height: 31.937px;
                            flex-shrink: 0;
                            border-radius: 16px;
                        }
                    }

                    .text-container {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        font-feature-settings: 'clig' off, 'liga' off;

                        @media (max-width: 866px) {
                            font-size: 14px;
                        }
                        @media (max-width: 576px) {
                            font-size: 14px;
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }

        .management-container {
            position: relative;

            @media (max-width: 1120px) {
                padding: 0;
                gap: 32px;
            }

            @media (max-width: 576px) {
                padding-top: 48px;
            }

            .management-title-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 32px;
            }

            .management-card-container {
                width: calc(100%);
                display: flex;
                gap: 32px;
                position: relative;

                @media (max-width: 576px) {
                    padding-left: 0px;
                    padding-right: 0px;
                    width: calc(100%);
                    gap: 0px;
                }
            }
        }

        .afterword-container {
            margin-top: 64px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;

            .afterword {
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
            }

            .afterword-button {
                margin-top: 24px;
                margin-bottom: 64px;
            }
        }
    }
}
