.management-card {
    height: 436px;
    flex: 1;
    background-color: var(--color-primary);
    border-radius: 32px;
    box-shadow: 0px 16px 18px 0px rgba(73, 92, 136, 0.15);
    position: relative;
    padding-left: 16px;
    padding-right: 16px;

    @media (max-width: 1120px) {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        margin-top: 32px;
        margin-bottom: 32px;

        .image {
            width: 151px;
            border-radius: 24px;
            height: 151px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;

            @media (max-width: 1120px) {
                height: 250px;
                width: 425px;
                margin: auto;
                background-position-y: 15%;
                margin-bottom: 16px;
            }

            @media (max-width: 576px) {
                height: 185px;
                width: 100%;
                border-radius: 24px 24px 0 0;
                margin-bottom: 32px;
            }
        }

        @media (max-width: 1120px) {
            height: 250px;
            width: 425px;
            margin: auto;
            background-position-y: 15%;
            margin-bottom: 16px;
        }

        @media (max-width: 576px) {
            height: 185px;
            width: 100%;
            border-radius: 24px 24px 0 0;
            margin-bottom: 32px;
        }
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 27px;
        margin-left: 27px;
        text-align: center;

        .management-title {
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 8px;

            @media (max-width: 576px) {
                font-size: 24px;
                margin-bottom: 16px;
            }
        }

        .management-description {
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            @media (max-width: 576px) {
                white-space: normal;
                margin-left: 16px;
                margin-right: 16px;
            }

            &.bulgarian {
                font-size: 14px;
            }
        }

        &.bulgarian {
            margin-right: 0px;
            margin-left: 0px;
        }
    }

    .button-container {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 1120px) {
            bottom: 0px;
        }

        @media (max-width: 576px) {
            bottom: 12px;
        }

        &.bulgarian {
            bottom: 6px;
        }
    }
}
