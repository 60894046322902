.shaded-content-laying-cards {
    margin-top: 32px;
    min-height: 208px;
    height: fit-content;

    border-radius: 24px;
    background: #ffffff;
    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 64px - 64px);
    overflow: hidden;

    @media (max-width: 992px) {
        width: calc(100% - 32px - 32px);
        margin-left: 32px;
        margin-right: 32px;
    }

    @media (max-width: 576px) {
        margin-top: 48px;
        height: 409px;
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
        height: fit-content;
    }

    .laying-card-container {
        display: flex;

        @media (max-width: 576px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0;
        }

        .laying-card-image {
            width: 208px;
            min-height: 100%;
            margin-right: 32px;
            object-fit: cover;
            object-position: center;

            @media (max-width: 992px) {
                margin-right: 16px;
            }

            @media (max-width: 754px) {
                margin-right: 10px;
            }

            @media (max-width: 576px) {
                width: 100%;
                margin-right: 0px;
                margin-top: -5px;
            }

            @media (max-width: 360px) {
                height: 145px;
            }
        }

        .laying-card-list {
            vertical-align: middle;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 14px;
            margin-left: 16px;
            padding-top: 10px;
            padding-bottom: 10px;

            @media (max-width: 754px) {
                font-size: 14px;
            }

            @media (max-width: 576px) {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin: 0;
                padding: 32px 16px 32px 16px;
                height: calc(242px - 8px - 32px);
                width: calc(100% - 16px - 16px);
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                @media (max-width: 576px) {
                    padding: 16px 16px 16px 16px;
                    width: calc(100% - 16px - 16px);
                }

                li {
                    white-space: normal;
                    float: left;
                    width: 100%;
                    word-wrap: break-word;
                    width: calc(100% - 1em);
                    margin-bottom: 8px;
                    font-family: var(--font-primary);

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;

                    @media (max-width: 360px) {
                        font-size: 14px;
                    }
                }
            }

            ul li::before {
                color: var(--color-green-main);
                content: '\2022';
                font-family: var(--font-primary);
                font-weight: bold;
                display: inline-block;
                margin-left: -1em;
                width: 1em;
            }
        }
    }
}
