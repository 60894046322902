.viewpager {
    width: calc(100%);

    .cards {
        white-space: nowrap;
        width: 100%;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        overflow-x: scroll;
        scroll-behavior: smooth;
        padding-bottom: 22px;
        overflow-y: auto;

        .card-item {
            width: calc(100%);
            padding: 0;
            display: inline-block;
            overflow-y: visible;
            scroll-snap-align: start;

            &.padding {
                width: calc(100% - 8px - 8px);
                padding: 8px;
            }
        }
    }

    @media (max-width: 576px) {
        ::-webkit-scrollbar {
            display: none;
        }
    }

    .indicator {
        display: inline-flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        gap: 18px;
        margin-top: 2px;

        .dot {
            width: 14px;
            height: 14px;
            border-radius: 7px;
            background-color: var(--color-light-grey);

            &.active {
                background-color: var(--color-yellow);
            }
        }
    }
}
